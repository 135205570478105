import React from 'react';

const style = {
  fontFamily: "'Russo One', sans-serif",
  fontSize: "75px",
  color: "red",
  textShadow: "2px 2px #FFFFFF",
};

class Timer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {secondsRemaining: 0};
  }

  formatTime(timeInSeconds, returnObj = false) {
    let modifiedTime = timeInSeconds;
    let timeObj = {
      hours: "00",
      minutes: "00",
      seconds: "00"
    };

    if (modifiedTime >= 3600) {
      timeObj.hours = ("0" + Math.floor(modifiedTime / 3600)).slice(-2);
      modifiedTime = modifiedTime - 3600 * timeObj.hours;
    }

    if (modifiedTime >= 60) {
      timeObj.minutes = ("0" + Math.floor(modifiedTime / 60)).slice(-2);
      modifiedTime = modifiedTime - 60 * timeObj.minutes;
    }

    if (modifiedTime >= 0) {
      timeObj.seconds = ("0" + modifiedTime).slice(-2);
    }

    if (returnObj) {
      return timeObj;
    }

    return `${timeObj.hours}:${timeObj.minutes}:${timeObj.seconds}`;
  }

  componentDidMount() {
    setInterval(async () => {
      const response = await fetch('https://znc.nate.aero/api/runtime');
      const json = await response.json();
      this.setState({ secondsRemaining: json.secondsRemaining });
    }, 1000);
  }

  render() {
    return (
      <div>
        {this.formatTime(this.state.secondsRemaining)}
      </div>
    )
  }
}

function App() {
  return (
    <div className="App" style={style}>
      <Timer />
    </div>
  );
}

export default App;
